<template>
  <div>
    <section class="main-block ">
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px] pt-[300px]">
            <h1 class="text-[52px] col-span-12 text-white font-bold">О нас</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-[#f6f6f6]">
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px]">
            <h2 class="text-[36px] col-span-8 font-bold">Мы переосмысливаем то, как движется мир</h2>
            <div class="col-span-9">
              <p class="text-[16px] font-normal leading-[24px] mb-[20px]">
                Мы – команда профессиональных специалистов, которые работают вместе для достижения общей цели: достичь максимальной эффективности и успеха наших клиентов. Наша команда стремится постоянно развиваться и улучшать наши услуги, чтобы обеспечить качественное и доступное обслуживание наших клиентов.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px]">
            <div class="lg:col-span-6 col-span-12 lg:mb-0 mb-[36px]">
              <img src="@/assets/img/24.jpg" class="w-full" alt="">
            </div>
            <div class="lg:col-span-5">
              <h3 class="text-[30px] font-bold">Наша миссия</h3>
              <p class="mt-[20px] leading-[24px]">
                Улучшить жизнь миллионов людей с помощью инновационных решений в сфере логистики и транспорта. 
                Это можно сделать, предлагая эффективные решения для оптимизации процессов доставки. 
                Использование инновационных технологий и инструментов, таких как искусственный интеллект может значительно улучшить эффективность и быстроту доставки, а также снизить расходы и сократить время доставки.
                Эти решения могут использоваться для доставки различных товаров, от еды и лекарств до запчастей и бытовой техники. В конечном итоге, это поможет сделать жизнь людей более комфортной и удобной.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px]">
            <div class="lg:col-span-6 col-span-12 lg:mb-0 mb-[36px]">
              <img src="@/assets/img/23.jpg" class="w-full" alt="">
            </div>
            <div class="col-span-6">
              <h3 class="text-[30px] font-bold">Наши преимущества</h3>
              <p class="mt-[20px] leading-[24px]">
                Мы являемся инновационной компанией в Таджикистане, которая занимается разработкой собственных программных обеспечений для транспортно-логистической индустрии.
                Наши программы помогают оптимизировать процессы доставки и логистики, а также снизить расходы и сократить время доставки. 
                Мы также предлагаем другим компаниям возможность использовать наши программы, что помогает расширить их бизнес и улучшить качество услуг, которые они предоставляют. 
                Мы постоянно работаем над развитием и улучшением наших программ, чтобы обеспечить нашим клиентам лучшее качество услуг.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-[#f6f6f6]">
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <h2 class="text-[36px] mb-[36px] font-bold">Наша команда</h2>
          <div class="md:grid md:grid-cols-12 md:gap-[36px] lg:grid lg:grid-cols-12 lg:gap-[36px]">
            <div class="lg:col-span-4 md:col-span-6">
              <img src="@/assets/img/team1.jpg" class="mb-[36px] w-full" alt="">
              <p class="text-[16px] font-normal leading-[24px] mb-[20px]">
                Наша команда – это команда профессионалов, объединенных общей целью и поставленными задачами. Мы работаем в тесном единстве, придавая важность каждому мнению и идее. Мы придерживаемся принципа доверия и понимания, помогая друг другу.
              </p>
            </div>
            <div class="lg:col-span-4 md:col-span-6">
              <img src="@/assets/img/еуфь4.jpg" class="mb-[36px] w-full" alt="">
              <p class="text-[16px] font-normal leading-[24px] mb-[20px]">
                У нас работают лучшие специалисты самых разных профессий. 
                Самому молодому сотруднику в нашей компании 17 лет.
                Для нас главное не возраст и опыт работы, а ваши амбиции и желание развиваться.
              </p>
            </div>
            <div class="lg:col-span-4 md:col-span-6">
              <img src="@/assets/img/team2.jpg" class="mb-[36px] w-full" alt="">
              <p class="text-[16px] font-normal leading-[24px] mb-[20px]">
                Непрерывное развитее - залог успеха 
                Мы идём в ногу со временем: участвуем в хакатонах, экспериментируем стартапы и проводим интереснейшие тимбилдинги для наших ребят. 
                Мы умеем не только работать, но и отдыхать от повседневной работы.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px] md:p-[40px]">
            <div class="col-span-5 mt-[10px]">
              <h2 class="lg:text-[52px] md:text-[44px] text-[36px] lg:leading-[64px] font-bold mb-[40px]">Найди работу по душе</h2> 
              <div class="flex flex-wrap">
                <a target="_blank" href="http://job.gram.tj/index.html">
                  <div class="no-underline border-none rounded-[10px] leading-[20px] font-medium hover:opacity-80 mt-[12px] cursor-pointer duration-200 px-[25px] py-[14px] bg-black text-white">
                    Поиск доступных вакансий
                  </div>
                </a>
              </div>
            </div>
            <div class="col-span-6 lg:ml-auto lg:mt-0 mt-[30px]">
              <img src="@/assets/img/job.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-if="showButton" @click="scrollToTop" class="fixed bottom-[20px] right-[20px]">
      <button style="box-shadow: 0px 1px 31px 4px rgba(34, 60, 80, 0.2)" class="border-none p-[15px] bg-white rounded-full">
        <div class="flex">
          <img class="-rotate-90 w-[25px]" src="@/assets/img/arrow.svg" alt="">
        </div>
      </button>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Footer from '@/components/layouts/Footer.vue'

export default {
  components: {
    Footer,
  },
  data(){
    return {
      showButton: false,
    }
  },
  mounted(){
    window.scrollTo({ top: 0 });
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.showButton = window.scrollY > 700;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.showText = false
    },
  }
}
</script>

<style scoped>
.main-block {
  background-image: url(@/assets/img/Худжанд2.jpg);
  background-size: cover;
  top: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 100%;
  height: auto;
}
</style>