<template>
  <div>
    <section class="main-block ">
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px] pt-[300px]">
            <!-- <h1 class="text-[52px] col-span-12 text-white font-bold">Контакты</h1> -->
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px]">
            <div class="lg:col-span-6 col-span-12 lg:mb-0 mb-[36px] ">
              <iframe class="max-w-[800px] w-full" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d190.2426523831279!2d69.6281360447573!3d40.2783616421365!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38b1b3bdc3956fbf%3A0x6516ecec1fe2014e!2zdGF4aUdyYW0gLSDQodC10YDQstC40YEg0LfQsNC60LDQt9CwINGC0LDQutGB0LgsINC00L7RgdGC0LDQstC60Lgg0Lgg0YHQvtCy0LzQtdGB0YLQvdGL0YUg0L_QvtC10LfQtNC-0Lo!5e0!3m2!1sru!2s!4v1691662314641!5m2!1sru!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div class="lg:col-span-6 lg:m-[36px] flex justify-start pb-[20px] flex-col">
              <div class="flex items-center">
                <img class="w-[34px] mr-[20px]" src="@/assets/img/home_house_icon_143764.svg" alt="">
                <div class="">
                  <h4 class="lg:text-[18px] md:text-[18px] text-[15px] font-medium">Таджикистан, Худжанд, проспект И.Сомони 46а</h4>
                </div>
              </div>
              <div class="flex items-center mt-[20px]">
                <img class="w-[34px] mr-[20px]" src="@/assets/img/telephoneauricularoutlinedtoolsymbol_104986.svg" alt="">
                <div class="">
                  <a  href="tel:+992446200220" class="lg:text-[18px] md:text-[18px] text-[15px] font-medium">+992 44 620 02 20 </a>
                </div>
              </div>
              <div class="flex items-center mt-[20px]">
                <img class="w-[34px] mr-[20px]" src="@/assets/img/email_icon_176156.svg" alt="">
                <div class="">
                  <a href="mailto:info@gram.tj" class="lg:text-[18px] md:text-[18px] text-[15px] font-medium">info@gram.tj</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- <Footer></Footer> -->
</template>

<script>
import Footer from '@/components/layouts/Footer.vue'
export default {
  components: {
    Footer
  },
  data(){
    return {

    }
  },
  mounted(){
    var a = document.createElement('script')
    a.src = '//code.jivo.ru/widget/35Evbh1ssj'
    a.setAttribute("async", "async")
    a.className = 'jivo'
    document.head.appendChild(a)
    window.scrollTo({ top: 0 })
  },
}
</script>
<style scoped>
.main-block {
  background-image: url(@/assets/img/контакты.jpg);
  background-size: cover;
  top: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  min-height: 100%;
  height: auto;
}
</style>