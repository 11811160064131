<template>
  <div>
    <app-banner class="sticky top-0" v-if="device && city == 'khujand'"></app-banner>
    <div v-if="$route.name !== '404'">
      <HeaderVue></HeaderVue>
    </div>
    <transition>
      <router-view></router-view>
    </transition>
    <div v-if="$route.name !== 'app'">
      <div v-if="$route.name !== 'driverApp'">
        <div v-if="$route.name !== '404'">
          <Footer></Footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/layouts/Footer.vue'
import HeaderVue from '@/components/layouts/Header.vue';
import appBanner from "@/components/elements/appBanner.vue"

export default {
  components: {
    HeaderVue,
    Footer,
    appBanner
  },
  data() {
    return {
      device: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
      city: sessionStorage.getItem('city')
    }
  },
  watch: {
    '$route.name': function(newPage, oldPage) {
      if (newPage !== oldPage) {
        this.city = sessionStorage.getItem('city')
      }
    }
  },
}
</script>

<style>
.address-list::-webkit-scrollbar {
  display: none;
} 
*{
  user-select: none;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
