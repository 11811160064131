<template>
  <div>
  </div>
</template>

<script>
export default {
  mounted() {
    if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
      window.location.href = 'https://play.google.com/store/apps/details?id=com.iogram.traffic';
    }
  }
}
</script>