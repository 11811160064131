<template>
    <div v-if="this.$route.name !== 'app'">
        <div v-if="this.$route.name !== 'driverApp'" class="bg-black relative w-full z-50 select-none py-[10px]">
            <div class="max-w-7xl flex duration-300 container mx-auto">
                <div class="flex justify-center items-center text-white  relative">
                    <div @click="toHome()">
                        <img class="w-[130px] mb-[10px] cursor-pointer pl-[20px]" src="../../assets/img/Gram.svg" alt="">
                    </div>
                    <div class="flex items-center ml-[150px]">
                        <div @click="openList = !openList, openSecondList = false" class="flex hover:bg-[#333333] duration-300 p-[10px] rounded-full">
                            <div class="hidden md:hidden lg:block ">{{ $t('header.navigation.company') }}</div>
                            <img :class="`${openList ? 'rotate-180': ''} ml-[5px] mt-[3px] duration-150 hidden md:hidden lg:block`" src="@/assets/img/V1.svg" alt="1">
                        </div>
                        <div @click="openSecondList = !openSecondList, openList = false" class="cursor-pointer relative rounded-full hidden md:hidden lg:flex justify-center items-center p-[10px] hover:bg-[#333333]  duration-300">
                        <div class="flex">
                            <div class="">{{ $t('header.navigation.projects') }}</div>
                            <img :class="`${openSecondList ? 'rotate-180': ''} ml-[5px] mt-[3px] duration-150 hidden md:hidden lg:block`" src="@/assets/img/V1.svg" alt="1">
                        </div>
                        <div :class="`${openSecondList ? 'absolute ': 'hidden'} shadow-md  top-[55px] text-[14px] bg-white text-black py-[8px] rounded-b-lg`">
                            <router-link :to="`/${country}/${city}/ride`">
                                <div class="hover:bg-[#eceaea] text-[#545454] hover:text-black py-[8px] pr-[56px] pl-[16px]">{{ $t('header.projects.rides') }}</div>
                            </router-link>
                            <!-- <router-link to="/delivery">
                                <div class="hover:bg-[#eceaea] text-[#545454] hover:text-black py-[8px] pr-[56px] pl-[16px]">Доставки</div>
                            </router-link> -->
                            <router-link :to="`/${country}/${city}/driver`">
                                <div class="hover:bg-[#eceaea] text-[#545454] hover:text-black py-[8px] pr-[56px] pl-[16px]">{{ $t('header.projects.driver') }}</div>
                            </router-link>
                            <a target="_blank" href="https://hamroh.com/">
                                <div class="hover:bg-[#eceaea] text-[#545454] hover:text-black py-[8px] pr-[56px] pl-[16px]">{{ $t('header.projects.hamroh') }}</div>
                            </a>
                        </div>
                    </div>
                        <router-link @click="openList = false, openSecondList = false" :to="`/${country}/${city}/contacts`">
                            <div class="ml-[10px]">
                                <span class=" hidden md:hidden hover:bg-[#333333] p-[10px] rounded-full duration-300 lg:block cursor-pointer">{{ $t('header.navigation.contact') }}</span>
                            </div>
                        </router-link>
                    </div>
                    <div :class="`${openList ? 'absolute': 'hidden'} left-[270px] shadow-md top-[57px] text-[14px] bg-white text-black py-[8px] rounded-b-lg`">
                    <router-link @click="openList = false" :to="`/${country}/${city}/about`">
                        <div class="hover:bg-[#eceaea] text-[#545454] hover:text-black py-[8px] pr-[56px] pl-[16px]">{{ $t('header.company.about') }}</div>
                    </router-link>
                    <router-link @click="openList = false" :to="`/${country}/${city}/news`">
                        <div class="hover:bg-[#eceaea] text-[#545454] hover:text-black py-[8px] pr-[56px] pl-[16px]">{{ $t('header.company.blog') }}</div>
                    </router-link>
                    <a target="_blank" @click="openList = false" href="http://job.gram.tj/index.html">
                        <div class="hover:bg-[#eceaea] text-[#545454] hover:text-black py-[8px] pr-[56px] pl-[16px]">{{ $t('header.company.career') }}</div>
                    </a>
                    </div>
                </div>
                <div @click="changeLanguage" class="text-white  ml-auto md:pr-2  flex justify-center items-center ">
                    <div @click="openList = false, openSecondList = false" class="cursor-pointer p-[10px] duration-300 rounded-full lg:flex justify-center items-center hover:bg-[#333333] mr-1">
                    <img class="pr-3 h-[25.67px] w-[30.67px]" src="@/assets/img/Vector.svg" alt="">
                    <span >{{ this.$i18n.t('header.currentLanguage') }}</span>
                    </div>
                    <!-- <span class="cursor-pointer pr-[21px] border border-black bg-black w-[70px] h-9 rounded-[30px] flex justify-center items-center px-5 my-[14px] transition-colors hover:bg-[#333333] mr-2 duration-300">Вход</span>
                    <span class="border bg-white text-black w-[100px] h-9 text-center rounded-[30px] font-normal text-sm flex justify-center items-center px-20 my-[14px] transition-colors hover:bg-gray-200 cursor-pointer">Зарегистрироваться</span> -->
                </div>
                <img  @click="close = !close" class="md:flex lg:hidden md:border md:border-black md:hover:bg-[#333333] ml-2 px-3 duration-300 py-3 rounded-[50%]" src="@/assets/img/Frame1.svg" alt="3">
            </div>
        </div>

    </div>
    <div @click="openList = false" v-if="openList" class="fixed  z-40 w-full top-0 h-full">

  </div>
      <div @click="openSecondList = false" v-if="openSecondList" class="fixed  z-40 w-full top-0 h-full">

  </div>
  <div @touchmove="close = true" v-if="!close" class="absolute z-40 w-full top-0 h-full">

  </div>
  <div :class="`w-full h-auto absolute z-50 shadow-md bg-white p-[40px] lg:hidden ${close ? 'hidden' : ''}`">
    <div>
        <div @click="openListMobile = !openListMobile" class="text-[24px] select-none cursor-pointer">
            Компания
        </div>
        <div :class="`${openListMobile ? '': 'hidden'} `">
            <router-link @click="openListMobile = false, close = true" :to="`/${country}/${city}/about`">
                <div class="hover:bg-[#eceaea] text-[#545454] hover:text-black py-[8px] pr-[56px] pl-[16px]">О нас</div>
            </router-link>
            <router-link @click="openListMobile = false, close = true" :to="`/${country}/${city}/news`">
                <div class="hover:bg-[#eceaea] text-[#545454] hover:text-black py-[8px] pr-[56px] pl-[16px]">Блог</div>
            </router-link>
            <a target="_blank" @click="openListMobile = false, close = true" href="http://job.gram.tj/index.html">
                <div class="hover:bg-[#eceaea] text-[#545454] hover:text-black py-[8px] pr-[56px] pl-[16px]">Карьера</div>
            </a>
        </div>
        <div @click="openSecondListMobile = !openSecondListMobile" class="text-[24px] select-none cursor-pointer mt-[20px]">
            Проекты
        </div>
        <div :class="`${openSecondListMobile ? '': 'hidden'}`">
            <router-link @click="openSecondListMobile = false, close = true" :to="`/${country}/${city}/ride`">
                <div class="hover:bg-[#eceaea] text-[#545454] hover:text-black py-[8px] pr-[56px] pl-[16px]">Поездки</div>
            </router-link>
            <router-link @click="openSecondListMobile = false, close = true" :to="`/${country}/${city}/driver`">
                <div class="hover:bg-[#eceaea] text-[#545454] hover:text-black py-[8px] pr-[56px] pl-[16px]">Водитель</div>
            </router-link>
            <a target="_blank" href="https://hamroh.com/" @click="openSecondListMobile = false, close = true">
                <div class="hover:bg-[#eceaea] text-[#545454] hover:text-black py-[8px] pr-[56px] pl-[16px]">Hamroh</div>
            </a>
        </div>
        <div @click="openSecondListMobile = false, close = true" class="text-[24px] select-none cursor-pointer mt-[20px]">
            <router-link :to="`/${country}/${city}/contacts`">
                Контакты
            </router-link>
        </div>
    </div>
  </div>
</template>
<script>
export default {
    data () {
        return {
            close: true,
            openList: false,
            openSecondList: false,
            openListMobile: false,
            openSecondListMobile: false,
            city: 'khujand',
            country: sessionStorage.getItem('country'),

        }
    },
    mounted() {
        this.city = sessionStorage.getItem('city')
        const savedLanguage = sessionStorage.getItem('selectedLanguage');
            if (savedLanguage) {
                this.$i18n.locale = savedLanguage;
         }
    },
    methods: {
        changeLanguage() {
            const currentLanguage = this.$i18n.locale;
            const newLanguage = currentLanguage === 'ru' ? 'tj' : 'ru';
            this.$i18n.locale = newLanguage;
            sessionStorage.setItem('selectedLanguage', newLanguage);
        },
        toHome() {
            this.$router.push({path: `/${this.country}/${this.city}`})
        }
    },
    watch: {
        '$route.name': function(newPage, oldPage) {
            if (newPage !== oldPage) {
                this.city = sessionStorage.getItem('city')
                this.country = sessionStorage.getItem('country')
            }
        }
    }
}
</script>

<style scoped>

</style>