<template>
  <div>
    <section class="main-block ">
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px] pt-[300px]">
            <h1 class="text-[52px] col-span-12 text-white font-bold">{{ $t('aboutUs.textAbout.text1') }}</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-[#f6f6f6]">
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px]">
            <h2 class="text-[36px] col-span-12 font-bold">{{ $t('aboutUs.textAbout.text2') }}</h2>
            <div class="col-span-12">
              <p class="text-[18px] font-normal leading-[24px] mb-[10px]">
                {{ $t('aboutUs.textAbout.text3') }}
              </p>
            </div>
            <div class="col-span-12">
              <p class="text-[18px] font-normal leading-[24px] mb-[10px]">
                {{ $t('aboutUs.textAbout.text4') }}
              </p>
            </div>
            <div class="col-span-12">
              <p class="text-[18px] font-normal leading-[24px] mb-[10px]">
                {{ $t('aboutUs.textAbout.text5') }}
              </p>
            </div>
            <div class="col-span-12">
              <p class="text-[18px] font-normal leading-[24px] mb-[20px]">
                {{ $t('aboutUs.textAbout.text6') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px]">
            <div class="lg:col-span-6 col-span-12 lg:mb-0 mb-[36px]">
              <img src="@/assets/img/24.jpg" class="w-full" alt="">
            </div>
            <div class="lg:col-span-5">
              <h3 class="text-[30px] font-bold">{{ $t('aboutUs.whoWeAre.title1') }}</h3>
              <p class="mt-[20px] text-[18px] leading-[24px]">
                {{ $t('aboutUs.whoWeAre.text1') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px]">
            <div class="lg:col-span-6 col-span-12 lg:mb-0 mb-[36px]">
              <img src="@/assets/img/23.jpg" class="w-full" alt="">
            </div>
            <div class="col-span-6">
              <h3 class="text-[30px] font-bold"> {{ $t('aboutUs.whoWeAre.title2') }}</h3>
              <p class="mt-[20px] text-[18px] leading-[24px]">
                {{ $t('aboutUs.whoWeAre.text2') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px]">
            <div class="lg:col-span-6 col-span-12 lg:mb-0 mb-[36px]">
              <img src="@/assets/img/for.jpg" class="w-full" alt="">
            </div>
            <div class="col-span-6">
              <h3 class="text-[30px] font-bold">{{ $t('aboutUs.whoWeAre.title3') }}</h3>
              <p class="mt-[20px] text-[18px] leading-[24px]">
                {{ $t('aboutUs.whoWeAre.text3') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px]">
            <div class="lg:col-span-6 col-span-12 lg:mb-0 mb-[36px]">
              <img src="@/assets/img/mission.jpg" class="w-full" alt="">
            </div>
            <div class="col-span-6">
              <h3 class="text-[30px] font-bold">{{ $t('aboutUs.whoWeAre.title4') }}</h3>
              <p class="mt-[20px] text-[18px] leading-[24px]">
                {{ $t('aboutUs.whoWeAre.text4') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px]">
            <div class="lg:col-span-6 col-span-12 lg:mb-0 mb-[36px]">
              <img src="@/assets/img/view.jpg" class="w-full" alt="">
            </div>
            <div class="col-span-6">
              <h3 class="text-[30px] font-bold">{{ $t('aboutUs.whoWeAre.title5') }}</h3>
              <p class="mt-[20px] text-[18px] leading-[24px]">
                {{ $t('aboutUs.whoWeAre.text5') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px]">
            <div class="lg:col-span-6 col-span-12 lg:mb-0 mb-[36px]">
              <img src="@/assets/img/values.jpg" class="w-full" alt="">
            </div>
            <div class="col-span-6">
              <h3 class="text-[30px] font-bold">{{ $t('aboutUs.whoWeAre.title6') }}</h3>
              <p class="mt-[20px] text-[18px] leading-[24px]">
                {{ $t('aboutUs.whoWeAre.text6_1') }} <br><br>
                {{ $t('aboutUs.whoWeAre.text6_2') }} <br><br>
                {{ $t('aboutUs.whoWeAre.text6_3') }} 
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px]">
            <div class="lg:col-span-6 col-span-12 lg:mb-0 mb-[36px]">
              <img src="@/assets/img/our.jpg" class="w-full" alt="">
            </div>
            <div class="col-span-6">
              <h3 class="text-[30px] font-bold">{{ $t('aboutUs.whoWeAre.title7') }}</h3>
              <p class="mt-[20px] text-[18px] leading-[24px]">
                {{ $t('aboutUs.whoWeAre.text7') }} 
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-[#f6f6f6]">
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <h2 class="text-[36px] mb-[36px] font-bold">{{ $t('aboutUs.ourTeam.title') }}</h2>
          <div class="md:grid md:grid-cols-12 md:gap-[36px] lg:grid lg:grid-cols-12 lg:gap-[36px]">
            <div class="lg:col-span-4 md:col-span-6">
              <img src="@/assets/img/team1.jpg" class="mb-[36px] w-full" alt="">
              <p class="text-[18px] font-normal leading-[24px] mb-[20px]">
                {{ $t('aboutUs.ourTeam.text1') }}
              </p>
            </div>
            <div class="lg:col-span-4 md:col-span-6">
              <img src="@/assets/img/еуфь4.jpg" class="mb-[36px] w-full" alt="">
              <p class="text-[18px] font-normal leading-[24px] mb-[20px]">
                {{ $t('aboutUs.ourTeam.text2') }}
              </p>
            </div>
            <div class="lg:col-span-4 md:col-span-6">
              <img src="@/assets/img/team2.jpg" class="mb-[36px] w-full" alt="">
              <p class="text-[18px] font-normal leading-[24px] mb-[20px]">
                {{ $t('aboutUs.ourTeam.text3') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px] md:p-[40px]">
            <div class="col-span-5 mt-[10px]">
              <h2 class="lg:text-[52px] md:text-[44px] text-[36px] lg:leading-[64px] font-bold mb-[40px]">{{ $t('aboutUs.searchJob.title') }}</h2> 
              <div class="flex flex-wrap">
                <a target="_blank" href="http://job.gram.tj/index.html">
                  <div class="no-underline border-none rounded-[10px] leading-[20px] font-medium hover:opacity-80 mt-[12px] cursor-pointer duration-200 px-[25px] py-[14px] bg-black text-white">
                    {{ $t('aboutUs.searchJob.text') }}
                  </div>
                </a>
              </div>
            </div>
            <div class="col-span-6 lg:ml-auto lg:mt-0 mt-[30px]">
              <img src="@/assets/img/job.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-if="showButton" @click="scrollToTop" class="fixed bottom-[20px] right-[20px]">
      <button style="box-shadow: 0px 1px 31px 4px rgba(34, 60, 80, 0.2)" class="border-none p-[15px] bg-white rounded-full">
        <div class="flex">
          <img class="-rotate-90 w-[25px]" src="@/assets/img/arrow.svg" alt="">
        </div>
      </button>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Footer from '@/components/layouts/Footer.vue'

export default {
  components: {
    Footer,
  },
  data(){
    return {
      showButton: false,
    }
  },
  mounted(){
    window.scrollTo({ top: 0 });
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.showButton = window.scrollY > 700;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.showText = false
    },
  }
}
</script>

<style scoped>
.main-block {
  background-image: url(@/assets/img/Худжанд2.jpg);
  background-size: cover;
  top: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 100%;
  height: auto;
}
</style>