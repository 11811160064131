import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './style.css'
import 'flowbite';
import 'vue-select/dist/vue-select.css'
import vSelect from 'vue-select'
import axios from 'axios'
import store from './store'
import i18n from './i18n';

const axiosInstance = axios.create({
  baseURL: 'https://api-client-2taxi.gram.tj/api'
})

const app = createApp(App)

app.config.globalProperties.$axios = { ...axiosInstance }
app.component('v-select', vSelect)
app.use(router)
app.use(store)
app.use(i18n)
app.mount('#app')
