//Khujand
import { createRouter, createWebHistory } from 'vue-router'
import Home_kh from '../views/Tajikistan/Khujand/Home.vue'
import Ride_kh from '../views/Tajikistan/Khujand/Ride.vue'
import DriverDownload_kh from '../views/Tajikistan/Khujand/DriverDownload.vue'
import Download_kh from '../views/Tajikistan/Khujand/Download.vue'
import About_kh from '../views/Tajikistan/Khujand/About.vue'
import News_kh from '../views/Tajikistan/Khujand/News.vue'
import Contacts_kh from '../views/Tajikistan/Khujand/Contacts.vue'
import Driver_kh from '../views/Tajikistan/Khujand/Driver.vue'
//Dushanbe
import Home_d from '../views/Tajikistan/Dushanbe/Home.vue'
import Ride_d from '../views/Tajikistan/Dushanbe/Ride.vue'
import About_d from '../views/Tajikistan/Dushanbe/About.vue'
import News_d from '../views/Tajikistan/Dushanbe/News.vue'
import Contacts_d from '../views/Tajikistan/Dushanbe/Contacts.vue'
import Driver_d from '../views/Tajikistan/Dushanbe/Driver.vue'
//Astana
import Home_as from '../views/Kazakhstan/Astana/Home.vue'
import Ride_as from '../views/Kazakhstan/Astana/Ride.vue'
import About_as from '../views/Kazakhstan/Astana/About.vue'
import News_as from '../views/Kazakhstan/Astana/News.vue'
import Contacts_as from '../views/Kazakhstan/Astana/Contacts.vue'
import Driver_as from '../views/Kazakhstan/Astana/Driver.vue'
//Astana
import Home_al from '../views/Kazakhstan/Almaty/Home.vue'
import Ride_al from '../views/Kazakhstan/Almaty/Ride.vue'
import About_al from '../views/Kazakhstan/Almaty/About.vue'
import News_al from '../views/Kazakhstan/Almaty/News.vue'
import Contacts_al from '../views/Kazakhstan/Almaty/Contacts.vue'
import Driver_al from '../views/Kazakhstan/Almaty/Driver.vue'
//Tashkent
import Home_t from '../views/Uzbekistan/Tashkent/Home.vue'
import Ride_t from '../views/Uzbekistan/Tashkent/Ride.vue'
import About_t from '../views/Uzbekistan/Tashkent/About.vue'
import News_t from '../views/Uzbekistan/Tashkent/News.vue'
import Contacts_t from '../views/Uzbekistan/Tashkent/Contacts.vue'
import Driver_t from '../views/Uzbekistan/Tashkent/Driver.vue'
//Default
import LegalInfo from '../views/LeagalTJ.vue'
import NotFound from '../views/NotFound.vue'
import Legal from '../views/Legal.vue'
import LegalRU from '../views/LegalRU.vue'

let country = sessionStorage.getItem('country')
let currentCity = sessionStorage.getItem('city')
if (!currentCity) {
  currentCity = 'khujand'
  sessionStorage.setItem('city', currentCity)
}

if (!country) {
  country = 'TJ'
  sessionStorage.setItem('country', country)
}

const routes = [
  {
    path: '/',
    name: 'default',
    redirect: `${country}/${currentCity}`
  },

  //Dushanbe routes

  {
    path: '/TJ/dushanbe',
    name: 'home_d',
    component: Home_d,
  },
  {
    path: '/TJ/dushanbe/ride',
    name: 'ride_d',
    component: Ride_d,
  },
  {
    path: '/TJ/dushanbe/about',
    name: 'about_d',
    component: About_d,
  },
  {
    path: '/TJ/dushanbe/news',
    name: 'news_d',
    component: News_d,
  },
  {
    path: '/TJ/dushanbe/contacts',
    name: 'contacts_d',
    component: Contacts_d,
  },
  {
    path: '/TJ/dushanbe/driver',
    name: 'driver_d',
    component: Driver_d
  },

  //Khujand routes

  {
    path: '/TJ/khujand',
    name: 'home_kh',
    component: Home_kh,
  },
  {
    path: '/TJ/khujand/ride',
    name: 'ride',
    component: Ride_kh,
  },
  {
    path: '/driver/app',
    name: 'driverApp',
    component: DriverDownload_kh,
  },
  {
    path: '/app',
    name: 'app',
    component: Download_kh,
  },
  {
    path: '/TJ/khujand/about',
    name: 'about_kh',
    component: About_kh,
  },
  {
    path: '/TJ/khujand/news',
    name: 'news_kh',
    component: News_kh,
  },
  {
    path: '/TJ/khujand/contacts',
    name: 'contacts_kh',
    component: Contacts_kh,
  },
  {
    path: '/TJ/khujand/driver',
    name: 'driver_kh',
    component: Driver_kh
  },

  //Astana routes

  {
    path: '/KZ/astana',
    name: 'home_as',
    component: Home_as,
  },
  {
    path: '/KZ/astana/ride',
    name: 'ride_as',
    component: Ride_as,
  },
  {
    path: '/KZ/astana/about',
    name: 'about_as',
    component: About_as,
  },
  {
    path: '/KZ/astana/news',
    name: 'news_as',
    component: News_as,
  },
  {
    path: '/KZ/astana/contacts',
    name: 'contacts_as',
    component: Contacts_as,
  },
  {
    path: '/KZ/astana/driver',
    name: 'driver_as',
    component: Driver_as
  },

  // Almaty routes

  {
    path: '/KZ/almaty',
    name: 'home_al',
    component: Home_al,
  },
  {
    path: '/KZ/almaty/ride',
    name: 'ride_al',
    component: Ride_al,
  },
  {
    path: '/KZ/almaty/about',
    name: 'about_al',
    component: About_al,
  },
  {
    path: '/KZ/almaty/news',
    name: 'news_al',
    component: News_al,
  },
  {
    path: '/KZ/almaty/contacts',
    name: 'contacts_al',
    component: Contacts_al,
  },
  {
    path: '/KZ/almaty/driver',
    name: 'driver_al',
    component: Driver_al
  },

  //Tashkent

  {
    path: '/UZ/tashkent',
    name: 'home_t',
    component: Home_t,
  },
  {
    path: '/UZ/tashkent/ride',
    name: 'ride_t',
    component: Ride_t,
  },
  {
    path: '/UZ/tashkent/about',
    name: 'about_t',
    component: About_t,
  },
  {
    path: '/UZ/tashkent/news',
    name: 'news_t',
    component: News_t,
  },
  {
    path: '/UZ/tashkent/contacts',
    name: 'contacts_t',
    component: Contacts_t,
  },
  {
    path: '/UZ/tashkent/driver',
    name: 'driver_t',
    component: Driver_t
  },

  //Tashkent routes

  //Default routes 

  {
    path: '/404',
    name: '404',
    component: NotFound,
  },
  {
    path: '/legal',
    name: 'legal',
    component: Legal,
  },
  {
    path: '/legal/ru',
    name: 'legalRU',
    component: LegalRU
  },
  {
    path: '/legal/tj',
    name: 'legalTJ',
    component: LegalInfo,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  base: '/',
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
